import type { RecordSourceOptions } from 'o365-dataobject';

/** Sort data on the client side */
export function sortData<T>(pOptions: RecordSourceOptions, pData: T[], fields: any) {
    const sortOrder = pOptions.fields?.filter(x => x.sortOrder != null);
    if (sortOrder && sortOrder.length > 0) {
        sortOrder.sort((a, b) => a.sortOrder! - b.sortOrder!);
        for (const item of sortOrder) {
            const field = fields[item.name];
            const fieldType = field?.type ?? 'string';
            pData.sort(sortByKey(item.name, item.sortDirection, fieldType));
        }
    }

    return pData;
}

/** Get a sort function for a specific item key */
export function sortByKey(pKey: string, pOrder: 'asc' | 'desc' | null | undefined, pType: any) {
    console.log(pKey, pOrder, pType);
    return (_a: any, _b: any) => {
        let a = _a[pKey];
        let b = _b[pKey];
        if (pType === 'string') {
            if (a && b) {
                if (a !== null) { a = a.toString().toUpperCase(); }
                if (b !== null) { b = b.toString().toUpperCase(); }
            } else if (a === undefined || a === '' || b === undefined || b === '') {
                if (a === b) {
                    return 0;
                } else if (a === undefined || a === '') {
                    return pOrder === 'asc' ? 1 : -1;
                } else if (b === undefined || b === '') {
                    return pOrder === 'asc' ? -1 : 1;
                }
            }
        } else if (['datetime', 'date'].includes(pType)) {
            a = new Date(a as string);
            b = new Date(b as string);
        }
        switch (pType) {
            case 'number':
                return pOrder === 'asc' ? (a - b) : (b - a);
            case 'string':
            case 'date':
            case 'datetime':
            case 'uniqueidentifier':
                return pOrder === 'asc' ? ((a < b) ? -1 : (b < a) ? 1 : 0) : ((a > b) ? -1 : (b > a) ? 1 : 0);
            default:
                return 0;
        }
    };
}